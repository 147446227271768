<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on: menu }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              text
              icon
              tile
              v-on="{ ...menu, ...tooltip }"
              style="margin-left: 10px"
            >
              <img :src="checkLanguage" alt="ev flag" width="38" />
            </v-btn>
          </template>
          <span>{{ $t("tooltip.language") }}</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="setCountry(item.value)"
        >
          <img :src="item.img" alt="" width="30" style="margin-right: 5px" />
          <v-list-item-title> {{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import store from "../../store";

export default {
  data() {
    return {
      items: [
        { img: "/img/flag/ja.png", title: "Japanese", value: "ja" },
        { img: "/img/flag/en.png", title: "English", value: "en" },
      ],
    };
  },
  created() {
    // CHECK FOR LANGUAGE LOCALE
    if (store.state.userinfo.Position) this.$i18n.locale = "en";
    else this.$i18n.locale = "ja";
  },
  methods: {
    // SET LANGUAGE ONCHANGE
    setCountry(val) {
      this.$i18n.locale = val;
    },
  },
  computed: {
    // CHANGE FLAG BASED ON LANGUAGE
    checkLanguage() {
      if (this.$i18n.locale == "en") return "/img/flag/en.png";
      else return "/img/flag/ja.png";
    },
  },
};
</script>