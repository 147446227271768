import UsersTable from "../functions/httpRequest/ExternalConnection/UsersTable";
import GcPortalTable from "../functions/httpRequest/OtherAPI/GCPortalAPI";
import md5 from "md5";

export default {
  /**
   * AUTO LOGIN FOR GC PORTAL AND RULEBOOK
   * ! FOR NOW DISABLED
   * @param {String} Email email to be check
   * @param {Function} callback callback for getting user pass
   */

  async Autologin(Email, callback) {
    const info = {}; //VARIABLE FOR MAIN RECORD
    let userinfo; // STORE RECORD IF EXIST. GcUser || rulebookUser
    let gcUser = [];
    let rulebookUser = [];

    //*************************************************//
    // GET RECORDS FROM RULEBOOK AND GC PORTAL USER    //
    //*************************************************//

    await GcPortalTable.getAll().then((res) => {
      const checkUser = res.data.filter((el) => md5(el.MailAddress) == Email);
      gcUser = checkUser;
    });
    await GcPortalTable.GetAllRulebook().then((res) => {
      const checkUser = res.data.filter((el) => md5(el.MailAddress) == Email);
      rulebookUser = checkUser;
    });

    //*************************************************//
    //       CHECK IF USER EXIST IN BOTH API           //
    //*************************************************//

    if (gcUser.length === 0 && rulebookUser.length === 0) {
      UsersTable.getSpecificEmail(Email).then((res) => {
        res.data.UserType = "Ichijo";
        callback(res.data);
      });
    } else {
      // IF NOT RECORD IN GC API
      if (gcUser.length === 0) {
        info.UserType = "Ichijo";
        userinfo = rulebookUser;
      } else {
        info.UserType = "Gc";
        userinfo = gcUser;
      }

      // PASS RECORD IN CALLBACK
      info.EmployeeCode = userinfo[0].UserId;
      info.FullName = userinfo[0].Name;
      info.Type = "Inquiry";
      info.Email = userinfo[0].MailAddress;
      callback(info);
    }
  },
};
