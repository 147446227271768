import axios from "axios";
// FOR GC PORTAL HEADERS AND URL
const BaseUrlGC = "https://9rk5no11y2.execute-api.us-east-2.amazonaws.com/prod";
const apiKeyGC = {
  headers: { "x-api-key": "yrzZIOXHw5M3creXNV63amuXO5pEAIxJubqiDn70" },
};
// FOR RULEBOOK HEADERS AND URL
const BaseUrlRulebook =
  "https://v7ck8ajvp1.execute-api.us-east-2.amazonaws.com/prod";
const apiKeyRulebook = {
  headers: { "x-api-key": "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3" },
};
// FOR ALL USER HEADERS AND URL
const BaseUrlUSERS =
  "https://1ze9v428i9.execute-api.us-east-2.amazonaws.com/prod";
const apiKeyUSERS = {
  headers: { "x-api-key": "JUe7mHXOhR6ziaSDHXUDJ310nTfdsH8s1ph44z2q" },
};
const httpRequest = {
  getAllUSERS() {
    return Promise.resolve(axios.get(`${BaseUrlUSERS}/getUsers`, apiKeyUSERS));
  },
  //*******************************************************//
  //       GET DOCUMENT SEARCH FROM GCPORTAL API           //
  //******************************************************//

  getAll() {
    return Promise.resolve(axios.get(`${BaseUrlGC}/users/AllUsers`, apiKeyGC));
  },

  //******************************************************************//
  //         GET SPECIFIC DOCUMENT SEARCH FROM GC PORTAL API          //
  //******************************************************************//

  getSpecific(email) {
    return Promise.resolve(
      axios.get(`${BaseUrlGC}/users/getUserInfo/${email}`, apiKeyGC)
    );
  },

  //*******************************************************//
  //       GET DOCUMENT SEARCH FROM RULEBOOK API           //
  //******************************************************//
  GetAllRulebook() {
    return Promise.resolve(
      axios.get(`${BaseUrlRulebook}/getAllUsers`, apiKeyRulebook)
    );
  },

  //******************************************************************//
  //         GET SPECIFIC DOCUMENT SEARCH FROM RULEBOOK API           //
  //******************************************************************//
  getSpecificRulebook(email) {
    return Promise.resolve(
      axios.get(`${BaseUrlRulebook}/getUserDetails/${email}`, apiKeyRulebook)
    );
  },
//*******************************************************//
  //       GET DOCUMENT SEARCH FROM RULEBOOK API           //
  //******************************************************//
  GetAllUsersAPI() {
    return Promise.resolve(
      axios.get(`${BaseUrlRulebook}/getAllUsers`, apiKeyRulebook)
    );
  },
};
export default httpRequest;
